var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"index"}},[_c('dv-full-screen-container',{staticClass:"bg"},[_c('div',{staticClass:"host-body"},[_c('Menu',{attrs:{"name":"device-elevator"},on:{"change":function($event){return _vm.onProInfoChange($event)}}}),(_vm.loading)?_c('dv-loading',[_vm._v("Loading...")]):_c('div',{attrs:{"id":"content"}},[_c('div',{attrs:{"id":"center"}},[_c('div',{staticClass:"mapContainer",style:({
              backgroundImage: _vm.bgImg,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat'
            })},_vm._l((_vm.showDevices),function(item){return _c('div',{key:item.Id,style:({
                left:(item.Pos ? item.Pos.Lat : 0)  +'px',
                top:(item.Pos ? item.Pos.Lng : 0) +'px',
                position: 'absolute'
              }),attrs:{"name":item.Name},on:{"click":function($event){return _vm.clickDevice(item)}}},[(item.Status === 2)?_c('div',{staticStyle:{"border-radius":"50%","filter":"alpha(Opacity=80)","position":"absolute","width":"100%","height":"100%"},style:({
                  background: 'rgba(0, 60, 255, 0.6)'
                })},[_c('img',{staticClass:"position",attrs:{"id":item.Id,"name":item.Name,"title":item.Name,"src":require("@/assets/image/elevator/elevator-online.png")}})]):(item.Status == 1)?_c('div',{staticStyle:{"border-radius":"50%","filter":"alpha(Opacity=80)","position":"absolute","width":"100%","height":"100%"},style:({
                  background: 'rgba(80, 80, 80, 0.6)'
                })},[_c('img',{staticClass:"position",attrs:{"id":item.Id,"name":item.Name,"title":item.Name,"src":require("@/assets/image/elevator/elevator-offline.png")}})]):_c('div',{staticStyle:{"border-radius":"50%","filter":"alpha(Opacity=80)","position":"absolute","width":"100%","height":"100%"},style:({
                  background: 'rgb(216,30,6,0.6)'
                })},[_c('img',{staticClass:"position",attrs:{"id":item.Id,"name":item.Name,"title":item.Name,"status":item.Status,"src":require("@/assets/image/elevator/elevator-worry.png")}})])])}),0)]),_c('div',{attrs:{"id":"right"}},[_c('div',{staticClass:"up"},[_c('h3',[_vm._v("近期报警")]),_c('dv-scroll-board',{ref:"alarmBox",attrs:{"config":_vm.config}})],1),_c('div',{staticClass:"down"},[_c('h3',[_vm._v("设备列表")]),_c('div',{staticClass:"leftMain",staticStyle:{"overflow":"auto","height":"100%"}},[_c('div',{staticClass:"monitor-device-list-link flex-column align-center module",staticStyle:{"position":"relative","z-index":"1","overflow":"auto"}},_vm._l((_vm.showDevices),function(item){return _c('div',{key:item.Id,staticClass:"monitor-device-item-link flex-row align-center",on:{"click":function($event){return _vm.clickDevice(item)}}},[_c('div',[_c('span',{staticClass:"trans-o-left"},[_vm._v(_vm._s(item.EquipmentRecordNo))]),_c('span',{staticClass:"trans-o-left"},[_vm._v(_vm._s(item.Name))]),_c('span',{staticClass:"trans-o-left"},[_vm._v(_vm._s(item.Code))]),(item.Status == 1)?_c('span',{staticClass:"trans-o-left"},[_vm._v("离线")]):(item.Status == 2)?_c('span',{staticClass:"trans-o-left"},[_vm._v("在线")]):_c('span',{staticClass:"trans-o-left"},[_vm._v("离线")])])])}),0)])])])])],1),_c('elevator-detail',{ref:"elevator",attrs:{"parentObj":this}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }